*{
  font-family: 'Poppins', sans-serif;
  color: white;
  scroll-behavior: smooth;
}

html {
  min-height: 100% !important;
  height: 100% !important;
  width: 100% !important;
 
}

.logo {
  opacity: 0;
}

.battle-text{
  font-size: 14px;
  font-weight: 300;
}

body {
  padding: 0px;
  margin: 0px;
  display: block;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: 100% !important;
  width: 100% !important;
  background-color: #070016 !important;
}

body::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.modal-body::-webkit-scrollbar{
  display: none;
}

.no-pad {
  padding: 0 !important;
}

.no-flow {
  overflow-x: hidden !important;
}

.my-pad {
  padding: 0% 10% !important;
}

p {
  margin: 0 !important;
}

.hidden {
  opacity: 0;
}

.nav-link.active{
  background-color: transparent !important;
  border-top: transparent !important;
  border-left: transparent !important;
  border-right: transparent !important;
  border-bottom: 4px solid #6F48E7 !important;
}

.nav-link:hover {
  border-color: transparent !important;
}

.tab-wrapper {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  border: none !important;
  z-index: 99 !important;
  padding: 1% 0% 1% 0%;
  background: linear-gradient(180deg, #000000 41.52%, rgba(0, 0, 0, 0.8) 70.41%, rgba(0, 0, 0, 0) 99.69%);
}

.dash-sect-wrapper {
  padding: 5% 0% 0% 0% !important;
}

.fighter {
  border-radius: 12px !important;
}
.fighter-div {
  background: linear-gradient(180deg, #070016 0%, rgba(7, 0, 22, 0.8) 40.63%, rgba(7, 0, 22, 0.4) 75%, rgba(7, 0, 22, 0) 99.48%);
  position: relative;
  width: 65% !important;
}
.first-fighter {
  border-radius: 12px !important;
}
.overlay-wrapper{
  position: relative;
}
.fighter-card {
  position: relative;
  text-align: center;
  margin-right: 24px;
  width: fit-content;
}
.fighter-cards {
  position: relative;
  text-align: center;
  margin-right: 24px;
  width: fit-content;
  padding-bottom: 13% !important;
}
.fighter-type {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
}
.fighter-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #131313;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}
.fighter-overlay:hover {
  transition: .2s;
  opacity: 0.9;
}
.fighter-overlay2 {
  background: linear-gradient(0deg, #070016 13.5%, rgba(7, 0, 22, 0.8) 21.63%, rgba(7, 0, 22, 0.4) 30%, rgba(7, 0, 22, 0) 39.48%);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0% !important;
  height: 270px;
  border-bottom: 1px solid #1D162D !important;
  border-radius: 0 !important;
}

.right-aligned {
  display: flex;
  flex-flow: column wrap;
  align-content: flex-end;
}

.left-aligned {
  flex-flow: column wrap;
  align-items: flex-start;
}

.fighter-title {
  font-size: 1.5em;
  font-style: italic;
  font-weight: 600;
}
.hidden-title {
  opacity: 0;
  font-size: 1.5em;
  font-style: italic;
  font-weight: 600;
}


.stats-desc {
  padding: 4% 0 1% 0;
  font-size: 1.125em;
  color: #707070;
  font-weight: 500 !important;
}

.fighter-type {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
}

.round-div {
  display: flex;
  justify-content: space-between;
  border: 1px solid #2D186D;
  border-radius: 14px;
  background: linear-gradient(180deg, #100031 0%, rgba(16, 0, 49, 0.8) 40.63%, rgba(16, 0, 49, 0.4) 75%, rgba(16, 0, 49, 0) 99.48%);
  padding: 3% 5% 3% 2%;
}

.fighter-row {
  height: fit-content !important;
}

.fighter-col {
  padding: 2% 1% 0 0 !important;
  justify-content: center;
}

.stats-col {
  padding: 2% 0% 0 1% !important;
  justify-content: center !important;
}

.stats-accord.accordion-item {
  border-radius: 14px !important;
}

.accordion-item {
  border-radius: 24px !important;
  background: linear-gradient(180deg, #0D0F1D 0%, rgba(13, 15, 29, 0) 100%) !important;
}

.ac-header.accordion-button {
  font-size: 1.125 !important;
}

.ac-header .accordion-item {
  border-radius: 14px !important;
}

.acc-mech.accordion-item {
  border-style: solid;
}

.acc-mech.accordion {
  --bs-accordion-bg: transparent !important;
  --bs-accordion-border-color: #181A2E !important;
  --bs-accordion-btn-color: white !important;
  --bs-accordion-active-color: white !important;
  --bs-accordion-active-bg: linear-gradient(180deg, #0D0F1D 0%, rgba(13, 15, 29, 0) 100%) !important;
  --bs-accordion-inner-border-radius: 24px !important;
  font-family: 'Poppins' !important;
  font-weight: 600 !important;
  border-radius: 24px !important;
  padding-bottom: 16px;
}

.wallet-id {
  position: fixed;
  z-index: 100 !important;
  padding: 1% 1%;
  right: 0px;
}

.overlay-btn{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent !important;
  border: none !important;
}

.drag-item {
  display: flex;
  position: relative !important;
  z-index: 2 !important;
}

.add {
  background: #FFFFFF;
  border-radius: 50%;
  padding: 5% 5%;
  opacity: 0.2;
}

.card {
  background: transparent !important;
  text-align: center;
}

.card-items {
  width: fit-content !important;
}

.card-image {
  border-radius: 12px !important;
}

.card-body {
  background: transparent !important;
}

.stat-wrap3 {
  padding: 2% 35% 0 0;
}

.stat-wrap2 {
  padding: 2% 0 0 35%;
}
.stat-wrap {
  padding: 10% 0 0 0%;
}

.fighter-div {
  background: linear-gradient(180deg, #070016 0%, rgba(7, 0, 22, 0.8) 40.63%, rgba(7, 0, 22, 0.4) 75%, rgba(7, 0, 22, 0) 99.48%);
  position: relative;
  width: 65% !important;
}

.fight-img-card {
  background-color: transparent !important;
  border: none !important;
}

.fight-overlay {
  background: linear-gradient(to top, #070016 0%, rgba(7, 0, 22, 0.8) 15.63%, rgba(7, 0, 22, 0.4) 35%, rgba(7, 0, 22, 0) 55.48%);
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.fight-img {
  border-radius: 14px !important;
  
}

.stats-body {
  background: #070016 !important;
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
}

.modal-select-team {
  padding: 2% 5% !important;
}
.modal-select-team::-webkit-scrollbar {
  display: none;
}
.modal-select-team-footer {
  border: none !important;
  padding: 1% 1% !important;
  justify-content: space-between !important;
  background: #000000 !important;
  height: 112px;
  transition: height 0.5s;
}
.modal-select-team-footer.active{
  display: flex;
  align-items: center;
  justify-content: center !important;
  height: 250px;
}

.first-footer{
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.second-footer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.second-footer p{
  padding-bottom: 1%;
}
.second-footer button{
  margin-top: 2%;
}

.drop-zone {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
}

.pair-counter{
  display: flex;
  justify-content: space-between;
  font-size: 1.125rem;
  font-weight: 600;
  padding-bottom: 6%;
}
.pair-wrapper{
  display: flex;
}

.pairs{
  display: flex;
}

.add-weirdo {
  width: 125px;
  height: 125px;
  margin-right: 16px;
  border: 1px solid #2D186D;
  border-radius: 24px;
  background: linear-gradient(180deg, #100033 0%, rgba(16, 0, 51, 0.8) 40.63%, rgba(16, 0, 51, 0.4) 75%, rgba(16, 0, 51, 0) 99.48%);
}
.add-scary {
  width: 125px;
  height: 125px;
  border: 1px solid #2D186D;
  border-radius: 24px;
  background: linear-gradient(180deg, #100033 0%, rgba(16, 0, 51, 0.8) 40.63%, rgba(16, 0, 51, 0.4) 75%, rgba(16, 0, 51, 0) 99.48%);
}

.select-pair-info {
  width: 620px;
}

.note-pair-counter {
  font-size: 1.125rem;
  font-weight: 600;
  margin-right: 39px !important;
}

.header-pad{
  padding: 4% 0% 4% 0%;
}

.select-pair-details {
  height: fit-content !important;
  padding: 0% 0% 5% 0% !important;
}
.select-pair-containers {
  height: fit-content !important;
  padding: 0% 0% 5% 0% !important;
}
.select-pair-details {
  height: fit-content !important;
}

.power{
  border-radius: 4px 4px 4px 0px;
  background-color: #6F48E7;
  position: absolute;
  top: 10px;
  right: 65px;
  padding: 0.5% 10%;
  width: fit-content;
  white-space: nowrap;
  font-size: 0.75rem;
  font-weight: 600;
  z-index: 5;
}
.power2{
  display: none;
  background-color: #6F48E7;
  position: absolute;
  z-index: 0;
  top: 14px;
  right: 103px;
  padding: 0.5% 10%;
  width: fit-content;
  white-space: nowrap;
  font-size: 0.75rem;
  font-weight: 600;
}

.select-fighter-wrapper{
  display: flex;
  align-items: center;
  height: fit-content;
  min-height: 199px;
  width: 100%;
  border: 1px solid #2D186D;
  border-radius: 24px;
  padding: 2% 2%;
  background: linear-gradient(180deg, #100033 0%, rgba(16, 0, 51, 0.8) 40.63%, rgba(16, 0, 51, 0.4) 75%, rgba(16, 0, 51, 0) 99.48%);
}

.sect-pairs{
  display: block;
}

.entry{
  display: flex;
}
.entry-item{
  display: flex;
  flex-direction: column;
  text-align: center;
}
.entry-item-wrapper{
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 0 !important;
  padding-bottom: 2% !important;
}
.entry-item-width{
  width: fit-content !important;
}
.entry-item-width:hover .remove-button2 path{
  fill: red;
}
.remove-button{
  opacity: 0.3;
  background-color: transparent;
  border: none;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14' fill='red'><path fill-rule='evenodd' d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'/></svg>") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  width: 25px;
  height: 25px;
}
.remove-button:hover{
  opacity: 1;
}

.hide{
  display: none;
}

.hidden-box {
  display: none;
}
.selected{
  border: 3px solid #6F48E7;
  border-radius: 12px;
}
.noPointer{
  pointer-events: none;
}

.footnote{
  justify-content: flex-start !important;
}
.footnote-func{
  display: flex;
  align-items: center;
}

.navbar-toggler {
  border: none !important;
  transition: background-color 0.2s ease-in-out;
}

.navbar {
  padding: 0 !important;
  position: absolute;
  width: 100%;
  z-index: 1 !important;
  font-size: 1.25em;
}

.center {
  position: absolute;
  width: 100% !important;
  justify-content: center;
  
}

.nav-link {
  color: inherit !important;
  text-decoration: none !important;
  color: #FFFFFF !important;
  font-weight: 600 !important;
  display: inline !important;
}

.nav-link:hover {
  color: #6F48E7 !important;
}
.nav-link:active {
  color: #231748 !important;
}

.landing-div {
  position: absolute;
  display: flex;
  width: 100%;
  background: linear-gradient(180deg, #000311 30%, rgba(0, 3, 17, 0.463542) 87.56%, rgba(0, 3, 17, 0) 100%), transparent;

  z-index: 1;
}

.nav-foot {
  display: none;
}

.div-wallet {
  display: none;
}

.div-explore {
  display: none;
}

.no-overflow {
  overflow-x: hidden;
}

.navfixed {
  position: fixed !important;
  background: linear-gradient(180deg, #070016 15.5%, rgba(7, 0, 22, 0.8) 40.24%, rgba(7, 0, 22, 0) 99.56%);
  padding: 1% 0 1% 0 !important;
}

.prize-right-hidden {
  opacity: 0;
}

.row  {
  margin: 0px !important;
  height: 100%;
}

.connect-div {
  padding: .5% 1% 0 2%;
}

.spinner{
  width: 125px !important;
  height: 125px !important;
  color: #6F48E7;
}

.spinner-screen {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 1500 !important;
}
.spinner-screen p{
  font-size: 1.75rem;
  font-weight: 600;
  padding-bottom: 2%;
}

.overlay-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.center-submit {
  position: absolute;
  width: 45px;
  height: 45px;
}

.stats-btn {
  font-size: 1.4em;
  font-weight: 600;
  color: #6F48E7 !important;
  background-color: transparent;
  border: none;
  bottom: 0px !important;
}

.crd-wrp{
  height: 100% !important;
}

.cards-position {
  padding-top: 2%;
  margin: 0 5% 0 5%;
}

.center-card {
  display: flex;
  flex-direction: column;
  flex: 0 !important;
  align-items: center;
  margin: .6% .2% !important;
}
.power-lvl {
  font-size: 1.2em !important;
  font-weight: 600 !important;
}
.rank-lvl {
  font-weight: 600 !important;
}
.explore:hover{
  transition: .2s;
  scale: 1.1;
}
.lvl {
  width: fit-content;
  height: fit-content;
  padding: 0 5% 0 5% !important;
  background-color: #6F48E7;
  border-radius: 4px !important;
  left: -9px !important; 
  top: 10px !important;
}
.rank {
  width: fit-content;
  height: fit-content;
  padding: 2% 6% !important;
  background-color: #000000;
  border-radius: 24px !important;
  left: 65% !important; 
  top: 10px !important;
}
.stats-btn {
  font-size: 1.4em;
  font-weight: 600;
  color: #6F48E7 !important;
  background-color: transparent;
  border: none;
  bottom: 0px !important;
}

.row-card {
  padding-bottom: 5%;
}
.bg-team {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) -0.57%, rgba(0, 0, 0, 0.3) 30.67%, rgba(0, 0, 0, 0.6) 67.63%, #000000 99.38%) ,url(./assets/images/backgrounds/active_fighters_bg.png);
  height: 55vh !important;
  background-size: cover;
  position: relative;
}

.col-text {
  position: absolute;
  bottom: 0;
  padding: 0% 0 2% 5.2%;
  font-size: 2em;
  font-weight: 600;
}

.cards-title {
  font-size: 2em;
  font-weight: 600;
  padding: 0% 0 2% 5.2% !important;
}
.st-title {
  font-size: 2em;
  font-weight: 600;
  padding: 0% 0 2% 5.2% !important;
}

.box-col {
  align-items: center !important;
  display: flex;
}

.box-wrp {
  padding-left: 10%;
}

.countdown-sect{
  background: linear-gradient(to top, #070016 0%, rgba(7, 0, 22, 0.8) 88.63%, rgba(7, 0, 22, 0.4) 95%, rgba(7, 0, 22, 0) 99.48%);
}

.rank-box {
  display: flex;
  text-align: center;
  border-radius: 24px;
  background-color: black;
  border: 1px solid;
  border-color: #1D162D;
  width: 425px;
  height: 196px !important;
  align-items: center;
  justify-content: center !important;
}

.rank-wrap {
  display: flex;
}
.rank-position {
  font-size: 2.25em;
  font-weight: 800;
}
.rank-desc {
  font-size: 1em;
  font-weight: 600;
}

.black {
  background-color: #000000;
}



.card {
  border-color: #1D162D !important;
}

.button{
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 10px 32px;
  width: fit-content;
  color: black;
  border-radius: 14px;
  background: #FFFFFF;
  border: none;
}

.accordion-body {
  display: flex;
  justify-content: space-between;
}

.none {
  opacity: 0;
}

.explore a{
  text-decoration: none !important;
}

.bg1 {

  background: linear-gradient(0deg, #070016 0%, rgba(7, 0, 22, 0.8) .63%, rgba(7, 0, 22, 0.4) 0%, rgba(7, 0, 22, 0) .48%) ,url(./assets/images/backgrounds/Hero-BG-IMG-2.jpg);
  background-position: center;
  background-size: cover;
}


.bgbg2 {
  background: linear-gradient(989.75deg, #070016 6.44%, rgba(6, 0, 22, 0) 52.37%, #070016 94.6%), 
              linear-gradient(180deg, #070016 .5%, rgba(7, 0, 22, 0.8) 5.63%, rgba(7, 0, 22, 0.4) 10.57%, rgba(7, 0, 22, 0) 15.48%),
              linear-gradient(0deg, #070016 .5%, rgba(7, 0, 22, 0.8) 5.63%, rgba(7, 0, 22, 0.4) 10.57%, rgba(7, 0, 22, 0) 0.48%),
              url(./static/images/collections.png);
  background-size: cover;
  background-position: center;
}

.bg3 {
  background: linear-gradient(90.03deg, #070016 0.03%, rgba(13, 1, 37, 0.542623) 9.93%, rgba(16, 0, 49, 0) 17.9%), 
  linear-gradient(180.03deg, #070016 0.03%, rgba(13, 1, 37, 0.542623) 9.93%, rgba(16, 0, 49, 0) 17.9%),
  linear-gradient(0.03deg, #070016 0.03%, rgba(13, 1, 37, 0.542623) 9.93%, rgba(16, 0, 49, 0) 17.9%),
  url(./static/images/landing.png);
  background-position: center;
  background-size: cover;
}

.bg4 {
  background: url(./static/images/mechanicsbg.png) #070016;
  background-size: cover;
  background-blend-mode: multiply;
}

.sect-landing {
  position: relative;
  height: 100vh;
}

.mid-text {
  background: #180F34;
  background-blend-mode: multiply;
  mix-blend-mode: normal;
  border-radius: 14px;
  padding: 10% 15%;
}
.text-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.sel-team{
  height: 67vh !important;
}
.center-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sect-title {
  white-space: nowrap;
  font-size: 3.31em;
  font-weight: 600;
  line-height: 1.1em;
  color: #FFFFFF;
  padding: 0% 0% 5% 0%;
}

.sect-desc {
  font-size: 1.1em;
  font-weight: 400;
  color: #D6D6D6;
  padding: 0 5% 5% 5%;
  text-align: center;
}

.center-connect {
  position: absolute;
  top: 35%;
  left: 0 !important;
  right: 0 !important;
}

.connect-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: purple;
  padding: 0.5% 2% 2% 2%;
  text-align: center;
  width: fit-content !important;
}

.countdown-wrapper {
  display: flex;
  flex-direction: row;
  padding: 0% 0% 5% 0%;

}

.timer-card {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  font-weight: 700;
  position: relative;
  background: linear-gradient(180deg, #100031 0%, rgba(16, 0, 49, 0.8) 40.63%, rgba(16, 0, 49, 0.4) 75%, rgba(16, 0, 49, 0) 99.48%);
  width: 229px;
  height: 280px;
  border: 1px solid #2D186D;
  border-radius: 24px;
  margin-right: 20px;
}
.timer-var {
  font-size: 1rem;
  font-weight: 600;
  position: absolute;
  bottom: 0;
  padding-bottom: 10%;
}

.sect-countdown {
  padding: 0% 0% 5% 0%;
}

.explore-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 3% 0% 3% 0%;
  background: linear-gradient(to top, #070016 0%, rgba(7, 0, 22, 0.8) 40.63%, rgba(7, 0, 22, 0.4) 75%, rgba(7, 0, 22, 0) 99.48%);
}

.explore {
  font-size: 1em;
  font-weight: 600;
  padding: 0.5% 1.5%;
  width: fit-content;
  color: #FFFFFF;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.3);
  background-blend-mode: multiply;
  border: none;
}


.expand-div {
  position: absolute;
  display: flex;
  padding: 10% 5% 0 0 !important;
  justify-content: right;
}

.expand-div2 {
  display: none;
}

.expand-all2 {
  font-size: 1em;
  font-weight: 600;
  padding: 2% 4% 2% 4%;
  width: fit-content;
  color: white;
  border-radius: 14px;
  background: linear-gradient(180deg, #070016 0%, rgba(7, 0, 22, 0.8) 40.63%, rgba(7, 0, 22, 0.4) 75%, rgba(7, 0, 22, 0) 99.48%);
  border: none;
  text-decoration: none;
}

.expand-all {
  font-size: 1em;
  font-weight: 600;
  align-content: center;
  padding: 0.5% 1.5%;
  width: fit-content;
  color: #FFFFFF;
  border-radius: 12px;
  background: linear-gradient(180deg, #070016 0%, rgba(7, 0, 22, 0.8) 40.63%, rgba(7, 0, 22, 0.4) 75%, rgba(7, 0, 22, 0) 99.48%);
  border: none;
}

a.explore:hover{
  color: rgb(255, 255, 255);
}

.anime {
  overflow-x: hidden;
}

a:link {
  text-decoration: none;
}

.info-wrapper {
  padding: 20% 10% 20% 10%;
}
.info-wrapper2 {
  padding: 20% 10% 20% 10% !important;
}
.info-wrapper-animations {
  padding: 7% 0% 7% 3.5%;
}
.info-wrapper-roadmap{
  padding: 7% 0% 3% 3.5%;
}
.info-wrapper-large {
  padding: 34% 10% 0% 10%;
}

.info-heading {
  font-size: 0.75rem;
  font-weight: 600;
  color: #FFFFFF;
}
.fighter-name {
  padding-top: 5%;
  font-size: 0.75rem;
  font-weight: 600;
  color: #FFFFFF;
}
.info-heading-grey {
  font-size: 0.75rem;
  font-weight: 600;
  color: #8C8C8C;
}

.info-heading-large {
  font-size: 5em;
  font-weight: 800;
  line-height: 1.1em;
  color: #FFFFFF;
  padding: 10% 10% 5% 0%;
}

.info-title {
  font-size: 2.31em;
  font-weight: 600;
  line-height: 1.1em;
  color: #FFFFFF;
  padding: 10% 0% 5% 0%;
}
.info-title-countdown {
  font-size: 2.31em;
  font-weight: 600;
  line-height: 1.1em;
  color: #FFFFFF;
  padding: 10% 0% 5% 0%;
}
.dash-title {
  padding-bottom: 2%;
  font-size: 2.31em;
  font-weight: 600;
  line-height: 1.1em;
  color: #FFFFFF;
}
.info-title-animations {
  font-size: 1.75em;
  font-weight: 600;
  line-height: 1.1em;
  color: #FFFFFF;
  padding: 4% 0% 0% 0%;
}

.info-desc {
  font-size: 0.875rem !important;
  font-weight: 400;
  color: #D6D6D6;
  padding: 0% 0% 5% 0%;
}
.dash-desc {
  font-size: 0.875rem !important;
  font-weight: 400;
  color: #D6D6D6;
}

.tag {
  color: #8C8C8C;
  font-size: 1.75rem;
}

.info-button:hover {
  background: #372570;
  transition: 0.2s;
}

.info-button:active {
  background: #231748;
}

.info-button-mechanics:hover {
  background: #372570;
  transition: 0.2s;
}

.info-button-mechanics:active {
  background: #231748;
}
.info-button-deactivate {
  position: relative;
  font-size: 1em;
  font-weight: 600;
  padding: 12px 32px;
  width: fit-content;
  color: #F3F3F3;
  border-radius: 14px;
  background: #383838;
  border: none;
  text-decoration: none;
  pointer-events: none;
  opacity: 0;
}
.info-button {
  position: relative;
  font-size: 1em;
  font-weight: 600;
  padding: 12px 32px;
  width: fit-content;
  color: white;
  border-radius: 14px;
  background: #6F48E7;
  border: none;
  text-decoration: none;
}
.info-button-connect {
  position: relative;
  font-size: 1em;
  font-weight: 600;
  padding: 12px 32px;
  width: fit-content;
  color: white;
  border-radius: 14px;
  background: #6F48E7;
  border: none;
  text-decoration: none;
}

.info-button-id {
  position: relative;
  font-size: 1em;
  font-weight: 600;
  padding: 6px 24px;
  width: fit-content;
  color: white;
  border-radius: 14px;
  background: #6F48E7;
  border: none;
  text-decoration: none;
}
.info-button-drag-drop {
  position: relative;
  font-size: 1em;
  font-weight: 600;
  padding: 12px 32px;
  width: fit-content;
  color: white;
  border-radius: 14px;
  background: #6F48E7;
  border: none;
  text-decoration: none;
}

.dash-connect{
  margin-right: 20px;
}


.collection-supply {
  font-size: 0.875em;
  font-weight: 600;
  color: #D6D6D6;
  padding: 0% 0% 5% 0%;
}

.prize-sm {
  height: 190px ;
  width: 190px ;
  background: transparent !important;
  border: none !important;
}

.prize-group {
  display: flex !important;
  flex-direction: column !important;
  width: fit-content !important;
}
.prize-sm .card-img-overlay {
  padding: 5% 0% 15% 5% !important;
}

.prize-md {
  height: 407px;
  width: 293px;
  background: transparent !important;
  border: none !important;
}

.prize-container {
  display: flex;
  width: 400px;
  justify-content: center;
  padding-top: 2.5%;
  transform: scale(1.5);
}

.prize-wrap {
  display: flex;
}

.prize-group-md .card-img-overlay {
  padding: 0% 4%;
}

.prize-group-mid {
  display: flex !important;
  flex-direction: column !important;
  width: fit-content !important;
  padding: 0% 4% 0% 4%;
}

.prize-title {
  font-size: 0.75em !important;
  font-weight: 600 !important;
  border-radius: 14px;
  padding: 3% 6%;
  background: rgba(19, 19, 19, 0.3);
  width: fit-content;
}

.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.battle-key {
  display: flex;
  justify-content: center;
  align-items: center;
}





.table-wrapper {
  padding: 15% 0% 0% 0%;
}

.darkpurple {
  background-color: #04000E !important;
}

.carousel-wrapper {
  padding: 2% 0 0% 0%;
}

.carousel-card-overlay {
  position: absolute;
  font-size: 1em;
  font-weight: 600;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #070016 0%, rgba(7, 0, 22, 0.8) 10.63%, rgba(7, 0, 22, 0.4) 35%, rgba(7, 0, 22, 0) 55.48%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 21px;
}

.vid-play {
  background-color: #FFFFFF !important;
  border: none !important;
  border-radius: 50% !important;
  height: 50px;
  width: 50px;
  opacity: 0.9 !important;
}

.vid-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0% 0% 3% 4%;
}

.modal-video-header {
  border: none !important;
}

.modal-content {
  border: none !important;
}

.modal-video-body {
  padding: 0% 10% !important;
  border: none !important;
}

.video-container {
  background: transparent !important;
}

.video-item {
  border-radius: 24px;
}


.carousel-indicators {
  display: none !important;
}

.carousel-card {
  margin-right: 20px;
}
.carousel-card-roadmap {
  margin-right: 60px;
  height: 200px;
  width: 380px;
}

.thumbnail {
  width: 400px;
  height: 380px;
  border-radius: 24px;
}

.carousel-control-next {
  position: absolute;
  height: fit-content !important;
  top: 50% !important;
}
.carousel-control-prev {
  position: absolute;
  height: fit-content !important;
  top: 50% !important;
}

video {
  width: 100%;
}

.sec-title {
  font-size: 1em;
  font-weight: 600;
  line-height: 16px;
  color: #FFFFFF;
  padding: 5% 0 3% 3.5%;
  text-align: left;
}



.modal-prizes::-webkit-scrollbar {
  display: none;
}

.modal-prizes {
  background-color: #070016;
}

.mybut {
  width: fit-content;
  margin-left: auto !important;
  border-radius: 50%;
  line-height: 20px;
}

.close-button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.modal-prize-header {
  background: #070016;
  border-bottom: 0 !important;
  font-size: 24px;
  font-weight: bold;
  
}

.modal-content {
  border: none;
  background-color: #070016 !important;
}


.modal-video-body {
  padding: 0 !important;
  background-color: transparent;
}

.modal-title {
  font-size: 36px;
  font-weight: bold;
  padding: 0% 1% 1% 6.5% !important;
}

.prize-col {
  margin-left: 45px !important;
  margin-right: 45px;
}

.test {
  display: flex;
  justify-content: center;
  padding-left: 0 !important;
}

.list-prize{
  padding-top: 4% !important;
}

.prize-div {
  display: flex;
  text-align: left;
  border-bottom: solid;
  border-color: #383838;
  border-bottom-width: .1em;
  padding-top: 24px;
  padding-bottom: 24px;
  height: 140px;
}

.prize-img {
  width: 87px;
  height: 87px;
  border-radius: 24px;
}

.prize-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  width: 100%;
  text-align: left;
}

.prize-name {
  padding-top: 8px;
}

.prize-image-wrapper {
  width: fit-content !important;
  padding: 0 !important;
}

.dark {
  background: #070016;
}

.darkblue {
  background: #070016;
}

.round {
  border-radius: 14px !important;
}

button:not(:disabled) {
  content: 'expand';
}

.table {
  border-color: #181A2E !important;
}

.wrapper {
  padding: 14.5% 6% 0% 4%;
  display: grid;
  align-items: center;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)) !important;
  
}

.card1 {
  border: 1px solid #181A2E !important;
  border-radius: 24px !important;
  background: transparent !important;
  background: linear-gradient(180deg, #0D0F1D 0%, rgba(13, 15, 29, 0) 50%) !important;
  padding: 0 !important;
}

.td-title {
  font-size: 1.1250em;
  font-weight: 600;
  padding: 1% 3% 1% 3% !important;
}

.tr-scen {
  padding: 0 0 0 5% !important;
}

.table-mechanics {
  border-top: transparent !important;
}

.table-mechanics td {
  border-right: 1px solid #181A2E;
}
.table-mechanics td:last-child {
  border-right: transparent;
}

.table-mechanics tr:last-child{
  border-bottom: transparent !important;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M12.6658 0.67751L7.32249 6.02084L1.96749 0.67751L0.322495 2.32251L7.32249 9.32251L14.3225 2.32251L12.6658 0.67751Z'/></svg>") !important;
}

.mechanics-center {
  padding: 42% 10% 20% 10%;
}

.thumbnail {
  width: 400px;
  height: 380px;
  border-radius: 24px;
}

.react-multi-carousel-track > li {
  width: fit-content !important;
}

.img-wrapper {
  position: relative;
}

.img-responsive {
  width: 100%;
  height: auto;
}

.img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.img-overlay:before {
  content: ' ';
  display: block;
  /* adjust 'height' to position overlay content vertically */
  height: 50%;
}





.thumbnail-btn {
  width: 60px !important;
  height: 60px !important;
  background-color: #D9D9D9 !important;
  border-radius: 50% !important;
  text-align: center !important;
}

.sec-tournament-details {
  padding-left: 90px;
  padding-bottom: 120px;
  padding-right: 126px;
}






.table-row {
  height: fit-content !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.accordion-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.accordion-col {
  height: fit-content;
}




.battle-key-img {
  background: linear-gradient(90.09deg, #040404 -0.03%, rgba(4, 4, 4, 0.542623) 15.56%, rgba(4, 4, 4, 0) 52.33%, rgba(4, 4, 4, 0.584366) 86.61%, #040404 99.92%) !important;
}

.site-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #000000;
}

.site-footer span{
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding-top: 48px;
  padding-bottom: 24px;
  color: #8C8C8C;
}

.socials {
  padding-bottom: 50px;
}


.sec-info-card-title {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #FFFFFF;
  padding-bottom: 12px;
}

.sec-info-card-header {
  font-size: 1.75em;
  font-weight: 600px;
  line-height: 32px;
  padding: 1% 0% 3% 0%;
}

.sec-info-card-details {
  font-size: 14px;
  font-weight: 400px;
  line-height: 22px;
  color: #D6D6D6;
  padding-bottom: 24px;
}


.sec-info-card-button {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 10px 32px;
  width: fit-content;
  color: #131313 !important;
  border-radius: 12px;
  background: #FFFFFF;
  border: none;
  text-decoration: none;
  text-align: center;
}

.sec-info-card-img {
  padding-top: 32px;
  
}

.img-gradient {
  position:relative;
}


.img-gradient::after {
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 100%; height: 100%;
  background: linear-gradient(90.09deg, #040404 -0.03%, rgba(4, 4, 4, 0.542623) 15.56%, rgba(4, 4, 4, 0) 52.33%, rgba(4, 4, 4, 0.584366) 86.61%, #040404 99.92%);
  background-blend-mode: multiply;
  mix-blend-mode: normal;
}

.acc-element {
  --bs-accordion-btn-padding-y: 3% !important;
  --bs-accordion-inner-border-radius: 14px !important;
  
}



.accordion {
  --bs-accordion-bg: transparent !important;
  --bs-accordion-border-color: #181A2E !important;
  --bs-accordion-btn-color: white !important;
  --bs-accordion-active-color: white !important;
  --bs-accordion-active-bg: linear-gradient(180deg, #0D0F1D 0%, rgba(13, 15, 29, 0) 100%) !important;
  font-family: 'Poppins' !important;
  border-radius: 24px !important;
  padding-bottom: 16px;
}

.mechanics-center .accordion-item {
  border-radius: 24px !important;
  background: linear-gradient(180deg, #0D0F1D 0%, rgba(13, 15, 29, 0) 100%) !important;
}

.accordion-button {
  font-family: 'Poppins' !important; 
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 28px !important;
  text-align: left !important;
  background: linear-gradient(180deg, #100031 0%, rgba(16, 0, 49, 0.8) 40.63%, rgba(16, 0, 49, 0.4) 75%, rgba(16, 0, 49, 0) 99.48%) !important;
  border: 1px solid #2D186D !important;
}

.accordion-heading {
  font-family: 'Poppins' !important;
  font-size: 80px !important;
}

.sect-rel {
  position: relative;
}

.roadmap-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}


.roadmap-element{
  overflow: hidden;
}

.roadmap-group {
  display: grid;
  gap: 24px;
  grid-auto-flow: column;
  border: 1px solid red;
}

.react-multi-carousel-list {
  overflow: visible !important;
}


.carousel-col {
  padding-right: 60px;
}
.carousel-col-animations {
  padding-right: 20px;
}
.info-button-mechanics {
  font-size: 1em;
  font-weight: 600;
  padding: 2% 4% 2% 4%;
  width: fit-content;
  color: white;
  border-radius: 14px;
  background: #6F48E7;
  border: none;
  text-decoration: none;
}

.roadmap-carousel .react-multiple-carousel__arrow {
  display: none !important;
}
.react-multiple-carousel__arrow--left {
  left: 0 !important;
}


.card-gradient-right-roadmap {
  display: none;
  position: absolute;
  z-index: 1;
  background: linear-gradient(to left, #070016 0%, rgba(7, 0, 22, 0.8) 40.62%, rgba(7, 0, 22, 0.4) 75%, rgba(7, 0, 22, 0) 99.48%) !important;
  mix-blend-mode: normal;
  height: 100%;
  width: 120px !important;
  right: 0;
}

.img-box-pre {
  width: 110px;
  height: 110px;
  border-radius: 12px;
  background-color: #140D21 ;
}



.reverse {
  transform: scale(-1, 1);
}
.round-header {
  font-weight: 800;
  padding-bottom: 10%;
  text-align: center;
}
.round-header2 {
  font-weight: 800;
  padding-bottom: 10%;
  text-align: center;
  transform: scale(-1,1);
}
.img-name2{
  width: fit-content;
  height: fit-content;
  font-size: 12px;
  font-weight: 600;
  color: white;
  transform: scale(-1,1);
  padding: 5% 0 0% 0;
}
.img-name{
  width: fit-content;
  height: fit-content;
  font-size: 12px;
  font-weight: 600;
  color: white;
  padding-top: 5%;
}
.img-box {
  width: 110px;
  height: 110px;
  border-radius: 12px;
}
.img-box-winner {
  width: 110px;
  height: 110px;
  border-radius: 12px;
  border: 4px solid #6F48E7;
}
.img-box-elim {
  width: 110px;
  height: 110px;
  border-radius: 12px;
  filter: grayscale(100%);
}
.bracket {
  white-space: nowrap;
  padding-top: 10%;
}
.bracket .round {
  display: inline-block;
  vertical-align: middle;
}
.bracket .round .winners > div {
  display: inline-block;
  vertical-align: middle;
  
}
.bracket .round .winners > div.matchups .matchup:last-child {
  margin-bottom: 0 !important;
}
.bracket .round .winners > div.matchups .matchup .participants {
  border-radius: 24px;
  height: fit-content;
  width: 142px;
  overflow: hidden;
  border: 1px solid #6B46DD;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bracket .round .winners > div.matchups .matchup .participants .participant {
  color: #858585;
  width: fit-content;
  height: fit-content;
  padding: 10% 10% 10% 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bracket .round .winners > div.matchups .matchup .participants .participantl {
  color: #858585;
  width: fit-content;
  height: fit-content;
  padding: 10% 10% 10% 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bracket .round .winners > div.matchups .matchup .participants .participant-selected {
  color: #858585;
  width: fit-content;
  height: fit-content;
  padding: 10% 10% 10% 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(111, 72, 231, 0.6);
  opacity: 75%;
}
.participant-selected img{
  border: 4px solid #6B46DD;
}
.bracket .round .winners > div.matchups .matchup .participants .participant-elim {
  background-color: #131313;
  width: fit-content;
  height: fit-content;
  padding: 10% 10% 10% 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.participant-elim img{

  filter: grayscale(1);
  border: 4px solid #6B46DD;
}
.bracket .round .winners > div.matchups .matchup .participants .participant:not(:last-child) {
  border-bottom: 1px solid #292929;
}
.bracket .round .winners > div.matchups .matchup .participants .participant span {
  margin: 0 1.25rem;
  line-height: 3;
  font-size: 1rem;
  font-family: "Roboto Slab";
}
.bracket .round .winners > div.connector.filled .line, .bracket .round .winners > div.connector.filled.bottom .merger:after, .bracket .round .winners > div.connector.filled.top .merger:before {
  border-color: #60c645;
}
.bracket .round .winners > div.connector .line, .bracket .round .winners > div.connector .merger {
  box-sizing: border-box;
  width: 2rem;
  display: inline-block;
  vertical-align: top;
}
.bracket-row{
  padding-bottom: 5% !important;
}
.bracket .round .winners > div.connector .line {
  border-bottom: thin solid #6B46DD;
  height: 11.65rem;
}
.bracket .round .winners > div.connector .merger {
  position: relative;
  height: 22.6rem;
}
.bracket .round .winners > div.connector .merger:before, .bracket .round .winners > div.connector .merger:after {
  content: "";
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 50%;
  border: 0 solid;
  border-color: #6B46DD;
}
.bracket .round .winners > div.connector .merger:before {
  border-right-width: thin;
  border-top-width: thin;
}
.bracket .round .winners > div.connector .merger:after {
  border-right-width: thin;
  border-bottom-width: thin;
}
.bracket .round.quarterfinals .winners:not(:last-child) {
  margin-bottom: .8rem;
}
.bracket .round.quarterfinals .winners .matchups .matchup:not(:last-child) {
  margin-bottom: 2rem;
}
.bracket .round.semifinals .winners .matchups .matchup:not(:last-child) {
  margin-bottom: 26.5rem;
}
.bracket .round.semifinals .winners .connector .merger {
  height: 47rem;
}
.bracket .round.semifinals .winners .connector .line {
  height: 23rem;
}
.bracket .round.finals .winners .connector .merger {
  height: 3rem;
}
.bracket .round.finals .winners .connector .line {
  height: 1.5rem;
}
.match1{
  margin-bottom: 3.7rem;
}
.match2{
  margin-top: 1rem;
}
.connector-space {
  padding-top: 35%;
}
.table>:not(caption)>*>* {
  border-bottom-width: 0px !important;
}
.final-round-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrapper-box {
  background: linear-gradient(180deg, #100033 0%, rgba(16, 0, 51, 0.8) 40.63%, rgba(16, 0, 51, 0.4) 75%, rgba(16, 0, 51, 0) 99.48%);
  padding-top: 7%;
}
.wrapper-box2 {
  background: linear-gradient(180deg, #100033 0%, rgba(16, 0, 51, 0.8) 40.63%, rgba(16, 0, 51, 0.4) 75%, rgba(16, 0, 51, 0) 99.48%), purple;
  padding: 5%;
}
.trophy-space{
  padding-bottom: 1%;
}
.final-round {
  font-size: 2.25rem;
  font-weight: 800;
  padding-bottom: 1%;
}
.final-img {
  width: 200px;
  border-radius: 12px;
}
.img-wr {
  width: 528px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.desc {
  font-size: 2.25rem;
  font-weight: 800;
  padding-bottom: 15%;
  font-style: italic;
}
.vid-play2 {
  background-color: #FFFFFF !important;
  border: none !important;
  border-radius: 50% !important;
  height: 80px;
  width: 80px;
  opacity: 0.9 !important;
}
.vid-wrapper{
  position: absolute;
  top: 23%;
  width: 400px;
  height: 225px;
}
.vid-caption2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 1.3rem;
  font-weight: 600;
  padding: 0% 0% 3% 4%;
}
.final-name{
  width: fit-content;
  height: fit-content;
  font-size: 18px;
  font-weight: 600;
  color: white;
  padding-top: 10%;
}
.previous-rounds{
  margin-top: 2%;
}
.title-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding-bottom: 3%;
}
.sub-title {
  font-style: italic;
  font-size: 2.25rem;
  font-weight: 400;
}
.main-title {
  font-size: 2.25rem;
  font-weight: 600;
}
.bottom-text {
  background: linear-gradient(180deg, #190047 .1%, rgba(13, 15, 29, 0) 2%, rgba(58, 0, 166, 0) 0%);
  font-size: 1.125rem;
  font-weight: 600;
  padding: 2% 1%;
  font-style: italic;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.rounds {
  font-weight: 600;
}
.rounds-desc {
  font-weight: 100 !important;
}
.page-link {
  color: #6B46DD !important;
  background-color: transparent !important;
  border-color: #6B46DD !important;
  cursor: pointer;
}
.page-link:hover {
  background-color: #221744 !important;
}
.page-link:active {
  background-color: #0c0818 !important;
}
.highlight {
  background-color: #6B46DD !important;
  color: white !important;
}
.fighter-img2 {
  border-radius: 20px !important;
  filter: grayscale(100%); 
}
.bracket-div {
  padding: 5% 0;
  width: 65%;
}
.bracket-sect{
  padding-top: 5%;
}
.bracket-table {
  width: 100%;
  border: 1px solid;
  border-color: #2D186D !important;
  align-self: center !important;
  border-radius: 24px !important;
  border-collapse: separate !important;
  background: linear-gradient(180deg, #190047 .1%, rgba(13, 15, 29, 0) 1.3%, rgba(58, 0, 166, 0) 0%);
  
}


.bracket-div {
  padding: 5% 0;
  width: 65%;
}

.header {
  background: linear-gradient(180deg, #190047 0%, rgba(13, 15, 29, 0) 100%, rgba(58, 0, 166, 0) 100%);
}

.round-title {
  text-align: center;
  font-weight: 600 !important;
  padding: 0 0 2% 0;
}

.green-text {
  color: #4CAF50;
}

.t-font {
  font-size: 1.125em;
  font-style: italic;
  border-top-right-radius: 24px;
}
.t-font1 {
  font-size: 1.125em;
  font-style: italic;
  border-top-left-radius: 24px;
  border-right: 1px solid #2D186D;
}

.td-title {
  font-size: 1.1250em;
  font-weight: 600;
  padding: 1% 3% 1% 3% !important;
}

.tr-scen {
  padding: 0 0 0 5% !important;
}

.bracket-table td {
  border-right: 1px solid #2D186D;
border-top: 1px solid #2D186D;
}
.bracket-table td:last-child {
  border-right: transparent;
  border-bottom: transparent;
}
.bracket-table td:first-child {
  border-bottom: transparent;
}

.bracket-table tr:last-child{
  border-bottom: transparent !important;
  
}

.acc-element {
  --bs-accordion-btn-padding-y: 3% !important;
  --bs-accordion-inner-border-radius: 14px !important;
  
}



@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.hero1 {
  animation: 1s ease-out 0s 1 slideInLeft;
  position: relative;
  height: 100vh;
}

.hero2 {
  animation: 1s ease-out 0s 1 slideInRight;
  height: 100vh;
  position: absolute;
  right: 0px;
}

@media screen and (max-width: 2200PX) {
  .prize-container {
    transform: scale(1.2) !important;
  }
  .info-desc {
    font-size: 1em !important;
  }
  .tag {
    color: #8C8C8C;
    font-size: .6em;
    font-weight: 500;
  }
  .info-desc {
    font-size: 0.875rem !important;
  }
}

@media screen and (max-width: 1700px) {
  .carousel-card-roadmap  {
    margin-right: 40px !important;
  }
}

@media screen and (max-width: 1440PX) {

  .navbar {
    font-size: 1em !important;
  }
  .connect-div {
    padding: .5% 1% 0 2.5%;
  }
  .connect-wallet {
    white-space: nowrap !important;
    display: none;
  }
  .info-heading-large {
    font-size: 3.5em !important;
  }
  .info-desc {
    font-size: 0.8750em !important;
    padding: 0% 0% 7% 0% !important;
  }
  .info-wrapper {
    padding: 15% 15% 15% 15% !important;
  }
  .info-wrapper-large {
    padding: 45% 5% 15% 15% !important;
  }
  .info-button {
    font-size: 1em !important;
    padding: 12px 32px;
  }
  .info-title {
    font-size: 1.750em !important;
  }
  .explore {
    font-size: 1em;
    font-weight: 600;
    padding: 0.5% 1.5%;
    width: fit-content;
    color: #FFFFFF;
    opacity: 1;
    border-radius: 12px;
  }
  .collection-supply {
    padding: 0 0 6% 0 !important;
  }
  .tag {
    color: #8C8C8C;
    font-size: .5em;
  }
  .prize-container {
    transform: scale(1);
    padding-top: 7% !important;
  }
  .wallet-id{
    margin-right: 1%;
  }
  
}

@media screen and (max-width: 1200px) {
  .prize-sm {
    height: 90px !important;
    width: 90px !important;
  }
  .prize-md {
    height: 205px !important;
    width: 147px !important;
  }
  .prize-container {
    transform: scale(1.5) !important;
  }
  .prize-title {
    transform: scale(.8);
  }
  .wallet-id{
    margin-right: 2%;
  }
}

@media screen and (max-width: 992px) {
  .info-heading-large {
    font-size: 3em !important;
  }
  .prize-sm {
    height: 90px !important;
    width: 90px !important;
  }
  .prize-md {
    height: 205px !important;
    width: 147px !important;
  }
  .prize-container {
    transform: scale(1.5) !important;
  }
  .prize-title {
    transform: scale(.8);
  }
  .hero1 {
    display: none;
  }
  .hero2 {
    display: none;
  }
  .bg1 {
    background-image: linear-gradient(0deg, #000311 0%, rgba(0, 3, 17, 0.463542) 5%, rgba(0, 3, 17, 0) 16.36%),
                      url(./static/images/tourny.png) !important;
  }
  .fighter-div {
    width: 100% !important;
  }
  .wallet-id{
    margin-right: 2%;
  }
  .info-button{
    padding: 6px 12px;
  }
  .info-button-deactivate{
    padding: 6px 12px;
  }
  .countdown-wrapper{
    transform: scale(0.7);
  }
}

.toggle-show {
  display: inline;
}

.toggle-hide {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .center {
    position: relative !important;
  }
  .carousel-card-roadmap {
    margin-right: 60px;
    height: 200px !important;
    width: 300px !important;
  }
  .info-wrapper-roadmap{
    padding: 7% 0% 3% 3.5% !important;
  }
  .info-button-connect{
    opacity: 0;
  }
  .info-button-deactivate{
    opacity: 1;
  }
  .info-button-mechanics {
    font-size: 1em;
    font-weight: 600;
    padding: 2% 4% 2% 4%;
    width: 100% !important;
    color: white;
    border-radius: 14px;
    background: #6F48E7;
    border: none;
    text-decoration: none;
  }
  .react-multiple-carousel__arrow {
    display: none !important;
  }
  .card-gradient-right-roadmap{
    display: none !important;
  }
  .thumbnail {
    width: 330px;
    height: 310px;
    border-radius: 24px;
  }
  .navbar-toggler {
    display: block;
  }
  .nav-foot {
    display: unset !important;
    width: 100% !important;
    position: absolute;
    bottom: 0px;
  }
  .navbar-collapse {
    flex-direction: column;
    width: 100%;
    background: linear-gradient(0deg, #070016, #070016), #FFFFFF;
    text-align: center;
    height: 100vh;
  }
  .nav-name {
    width: 100% !important;
    font-size: 1.125em !important;
    font-weight: 600;
    border-radius: 14px;
    background: #6F48E7 !important;
    border: none;
    text-decoration: none;
    margin-bottom: 20px;
    padding: 0% !important;
  }

  .div-explore{
    display: unset !important;
    opacity: 1 !important;
    font-size: 2.250em;
    font-weight: 800;
    padding: 0% 0 10% 0;
  }
  .div-wallet {
    display: unset !important;
    padding: 15% 0 0% 0 !important;
  }
  .nav-wrap {
    padding: 0 5% 0 5% !important;

  }
  .connect-wallet {
    display: none !important;
  }
  .nav-bar {
    height: 100vh !important;
    padding-top: 30%;
  }
  .info-heading-large {
    font-size: 2.5em !important;
  }
  .info-desc {
    font-size: 0.8750em !important;
  }
  .info-wrapper {
    padding: 15% 5% 15% 5% !important;
  }
  .info-wrapper-large {
    padding: 15% 5% 15% 5% !important;
  }
  .bg1 {
    height: 45vh;
    background-image: linear-gradient(0deg, #000311 0%, rgba(0, 3, 17, 0.463542) 5%, rgba(0, 3, 17, 0) 16.36%),
                      url(./static/images/tourny.png) !important;
  }
  .bg3 {
    height: 40vh;
    background-image: linear-gradient(180deg, #000311 0%, rgba(0, 3, 17, 0.463542) 40%, rgba(0, 3, 17, 0) 76.36%) ,
                      linear-gradient(0deg, #000311 0%, rgba(0, 3, 17, 0.463542) 5%, rgba(0, 3, 17, 0) 16.36%),
                      url(./static/images/landing.png) !important;
  }
  .bgbg2 {
    height: 20vh;
    background-image: linear-gradient(180deg, #000311 0%, rgba(0, 3, 17, 0.463542) 40%, rgba(0, 3, 17, 0) 76.36%) ,
                      linear-gradient(0deg, #000311 0%, rgba(0, 3, 17, 0.463542) 5%, rgba(0, 3, 17, 0) 16.36%),
                      url(./static/images/collections.png) !important;
  }
  .dark {
    padding-top: 0% !important;
  }
  .darkblue {
    background-image: linear-gradient(180deg, #000311 0%, rgba(0, 3, 17, 0.463542) 40%, rgba(0, 3, 17, 0) 76.36%) !important;
  }
  .explore {
    display: none;
  }
  .explore-wrapper {
    display: none !important;
  }
  .info-button {
    width: 100% !important;
    font-size: 1em !important;
  }
  .info-button-deactivate {
    width: 100% !important;
    font-size: 1em !important;
  }
  .expand-all {
    display: none;
  }
  .expand-all2 {
    width: 100% !important;
    font-size: 1.2em !important;
  }
  .info-title {
    padding: 0 !important;
  }
  
  .table-mechanics  {
    display: none;
  }
  .mechanics-img {
    display: none;
  }
  .sec-title {
    padding: 10% 0 3% 3.5% !important;
  }
  .sect-landing {
    height: fit-content !important;
  }
  .prize-sm {
    height: 90px !important;
    width: 90px !important;
  }
  .prize-md {
    height: 205px !important;
    width: 147px !important;
  }
  .powerdiv {
    padding-bottom: 10% !important;
  }
  .mech-wrap {
    padding: 15% 5% 3% 5% !important;
  }
  .mech-wrap2 {
    padding: 0% 5% 0% 5% !important;
  }
  .card1 {
    display: none;
  }
  .mechanics-center {
    padding: 0% !important;
  }
  .expand-div2 {
    display: unset !important;
  }
  .carousel-wrapper {
    padding: 7% 0 0% 0%;
  }
  .connect-div{
    display: none;
  }
  .center-section{
    display: none;
  }
  .info-title-countdown{
    font-size: 1.7rem;
  }
  .box-col{
    justify-content: center;
  }
  .box-wrp{
    padding: 0;
  }
  .wallet-id{
    bottom: 0;
    right: 0;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 414px) {
  .nav-foot {
    position: absolute;
    bottom: 0px;
  }
  .nav-link {
    display: block !important;
  }
  .nav-name {
    width: 100% !important;
    font-size: 1.125em !important;
    font-weight: 600;
    border-radius: 14px;
    background: #04091E;
    border: none;
    text-decoration: none;
    margin-bottom: 20px;
    padding: 4% 0 4% 0 !important;
  }
  .div-explore{
    display: unset !important;
    opacity: 1 !important;
    font-size: 2.250em;
    font-weight: 800;
    padding: 10% 0 10% 0;
  }
  .div-wallet {
    display: unset !important;
    padding: 15% 0 0% 0 !important;
  }
  .nav-wrap {
    padding: 0 5% 0 5% !important;

  }
  .nav-bar {
    height: 100vh !important;
    padding-top: 15%;
  }
  .prize-sm {
    height: 90px !important;
    width: 90px !important;
  }
  .prize-md {
    height: 205px !important;
    width: 147px !important;
  }
  .prize-container {
   width: 325px !important;
   transform: scale(1) !important;
  }
  .prize-title {
    display: none;
  }
  .prize-hidden {
    display: none;
  }
  .prize-right-hidden {
    opacity: 1 !important;
    width: 100%;
    padding: 6% 5% 16% 5%;
  }
  .col-w {
    padding: 15% 5% 8% 5% !important;
  }
  .col-s {
    padding: 5.5% 5% 15% 5% !important;
  }
  .info-title {
    padding: 5% 0% 5% 0% !important;
  }
  .info-title br {
    display: none;
  }
  .tag {
    color: #8C8C8C;
    font-size: .5em;
  }
  .pr-d {
    padding: 15% 5% 5% 5% !important;
  }
  .item1 {
    display: none;
  }
  .sect-countdown{
    padding-top: 7%;
  }
  .countdown-wrapper {
    padding: 0%;
    height: 150px;
    width: 550px;
  }
  .timer-card{
    height: 100%;
    background: transparent;
    border: none;
    margin-right: 0;
    align-items: flex-start;
  }
  .info-title-countdown{
    padding: 0%;
    font-size: 1.3rem;
  }
  .box-wrp{
    padding: 0;
  }
  .box-col{
    padding-top: 10% !important;
    justify-content: center;
  }
  .col-text{
    padding: 0;
  }
  .tab-item{
    font-size: 1rem !important;
  }
  .tab-wrapper .nav-link{
    padding: 5%;
  }
  .info-button-connect{
    display: none;
  }
  .info-button-deactivate{
    opacity: 1;
  }
}

@media screen and (max-width: 375px) {
  .prize-group-mid {
    padding: 0% !important;
  }
  .prize-container {
    transform: scale(1) !important;
  }
  .prize-right-hidden {
    padding: 3% 5% 3% 5% !important;
  }
}